import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Profile from "../assets/yo5.png";
import Resume from "../assets/RER_Resume.pdf";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import "./About.css";

const About = () => {
  return (
    <div id="about">
      <div className="about">
        <q className="pt-3 text-center font-details pb-3">
          I have not failed. I've just found 10,000 ways that won't work.
        </q>
        <p className="thomas">Thomas Edison</p>
        <Container>
          <Row className="pt-3 pb-5 align-items-center">
            <Col xs={12} md={6}>
              <Row className="img-me justify-content-center mb-2 mr-2 ">
                <Image
                  className="profile justify-content-end"
                  alt="profile"
                  src={Profile}
                  thumbnail
                  fluid
                />
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row className=" align-items-start p-2 my-details rounded">
                <strong>My Name is Ruben Rivera</strong>

                <p className="my-details">
                  Hello everyone! I'm a retired Marine pursuing my goal as a
                  software Developer. I currently live in Virginia, just outside
                  washington DC. I have always been curious about designing
                  websites; thus I decided to become a Web Developer.
                </p>

                <p className="my-details">
                  During my search I discovered Software Development. I enrolled
                  in a coding boot camp out of Washington DC with General
                  Assembly and it was the best decision I ever made. I decided
                  to continue my education and I successfully completed my
                  undergraduate program with the University of Maryland Global
                  Campus in Software Development and Security. I have also
                  completed my CompTia Security Plus Certification as well as
                  Ethical Hacker Certification.{" "}
                </p>
                <Col className="d-flex justify-content-center flex-wrap">
                  <div>
                    <a href="mailto:ruben.rivera.sf@outlook.com">
                      <Button className="m-2" variant="outline-info">
                        <h4 className="about-icons">Email</h4>
                      </Button>
                    </a>
                  </div>
                  <div>
                    <a href={Resume} target="_blank" rel="noopener noreferrer">
                      <Button className="m-2" variant="outline-info">
                        <h4 className="about-icons">My Resume</h4>
                      </Button>
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://github.com/rksd0923"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="m-2" variant="outline-info">
                        <h4 className="about-icons">Github</h4>
                      </Button>
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.linkedin.com/in/ruben-rivera/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="m-2" variant="outline-info">
                        <h4 className="about-icons">Linkedin</h4>
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default About;
