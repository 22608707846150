import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./Carousel.css";
import Slide1 from "../assets/blackbg.png";
import Slide2 from "../assets/blackbg.png";
import Slide3 from "../assets/blackbg.png";
import ScrollDown from "./scrolldown";


const MyCarousel = () => {
  return (
    <div id="home">
      <div className="home">
        <Carousel slide={false} controls={false} carousel-indicators={false}>
          <Carousel.Item interval={3500}>
            <img
              className="d-block w-100 custom-img"
              src={Slide1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={3500}>
            <img
              className="d-block w-100 custom-img"
              src={Slide2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item interval={3500}>
            <img
              className="d-block w-100 custom-img"
              src={Slide3}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
        <ScrollDown />
        
      </div>
      
    </div>
  );
};

export default MyCarousel;
