import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./Contact.css";

const Contact = () => {
  return (
    <div id="contact">
      <h1 className="pt-3 text-center font-details-b pb-3">CONTACT ME</h1>

      <Row>
        <Col className="d-flex justify-content-center flex-wrap">
          <div className="m-2">
            <a
              href="mailto:ruben.rivera.sf@outlook.com.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="outline-danger"
                title="ruben.rivera.sf@outlook.com"
              >
                <i className="fas fa-envelope-square"></i> Email
              </Button>
            </a>
          </div>
          <div className="m-2">
            <a
              href="https://linkedin.com/in/ruben-rivera"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="outline-info" title="Visit my LinkenIn">
                <i className="fab fa-linkedin"></i> LinkedIn
              </Button>
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
