import "./App.css";
import AnimCursor from "./components/AnimCursor";
import MyNavbar from "./components/MyNavbar";
import MyCarousel from "./components/MyCarousel";
import TitleMessage from "./components/TitleMessage";
import About from "./pages/About";
import MySkills from "./pages/MySkills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import ParticlesPage from "./components/ParticlesPage";
import React, { Component } from "react";
import Container from "react-bootstrap/Container";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div>
          <div>
            <MyCarousel />
          </div>
          {/*<ParticlesPage />*/}
          <MyNavbar />
          <AnimCursor />

          <TitleMessage />
        </div>
        <div className="color-gradient">
          <About />
          <Projects />

          <MySkills />

          <Contact />

          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
