import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import L_MockUp from "../assets/thumbnails/1st.jpeg";
import L_RonSwansonQuotes from "../assets/thumbnails/swanson.jpeg";
import L_TriviaGame from "../assets/thumbnails/map.jpeg";
import L_APICreation from "../assets/thumbnails/code.jpeg";
import L_PythonCLI from "../assets/thumbnails/apiDjango.png";
import L_FullStack from "../assets/thumbnails/project9.jpeg";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Image from "react-bootstrap/Image";
import L_HTML5 from "../assets/skills/html5.png";
import L_CSS3 from "../assets/skills/css3.svg";
import L_GIT from "../assets/skills/github-api.svg";
import L_JAVASCRIPT from "../assets/skills/javascript.svg";
import L_API from "../assets/thumbnails/swanson.jpeg";
import "./Projects.css";

const Projects = () => {
  return (
    <div id="projects">
      <div className="projects-body">
        <h1 className="pt-3 text-center font-details-b pb-3">PROJECTS</h1>

        <Container className="container-projects">
          <Row className="justify-content-center projects">
            <Col>
              <Row className="justify-content-center project">
                <Card style={{ width: "20rem" }}>
                  <Card.Img variant="top" src={L_TriviaGame} />
                  <Card.Body>
                    <Card.Title>Trivia game</Card.Title>
                    <Card.Text>
                    Application created with VanillaJS, HTML, CSS
                    </Card.Text>
                    <Button href="https://rksd0923.github.io/Project-4/" target="_blank" variant="primary">Demo</Button>
                    <Button href="https://github.com/rksd0923/Project-4" target="_blank"  variant="primary">Source Code</Button>
                  </Card.Body>
                </Card>
              </Row>
            </Col>
            <Col>
              <Row className="justify-content-center project">
              <Card style={{ width: "20rem" }}>
                  <Card.Img variant="top" src={L_PythonCLI} />
                  <Card.Body>
                    <Card.Title>Python API</Card.Title>
                    <Card.Text>
                      Api was created using Django and Python.
                    </Card.Text>
                    <Button href="https://protected-thicket-29055.herokuapp.com/companies/" target="_blank" variant="primary">Demo</Button>
                    <Button href="https://github.com/rksd0923/APIdjango" target="_blank" variant="primary">Source Code</Button>
                  </Card.Body>
                </Card>
              </Row>
            </Col>
            <Col>
              <Row className="justify-content-center project">
              <Card style={{ width: "20rem" }}>
                  <Card.Img variant="top" src={L_API} />
                  <Card.Body>
                    <Card.Title>API Integration</Card.Title>
                    <Card.Text>
                    API is used to fetch information from external source
                    </Card.Text>
                    <Button href="https://rksd0923.github.io/project-3/" target="_blank" variant="primary">Demo</Button>
                    <Button href="https://github.com/rksd0923/Project-3" target="_blank" variant="primary">Source Code</Button>
                  </Card.Body>
                </Card>
              </Row>
            </Col>
          </Row>
        </Container>

        {/* <Container className="container-projects">
          <Row className="justify-content-center projects">
            <Col>
              <Row className="justify-content-center project">
                <Card style={{ width: "20rem" }}>
                  <Card.Img variant="top" src={L_PythonCLI} />
                  <Card.Body>
                    <Card.Title>Python API</Card.Title>
                    <Card.Text>
                    Api was created using Django and Python.
                    </Card.Text>
                    <Button href="https://protected-thicket-29055.herokuapp.com/" target="_blank" variant="primary">Demo</Button>
                    <Button href="https://github.com/rksd0923/APIdjango" target="_blank" variant="primary">Source Code</Button>
                  </Card.Body>
                </Card>
              </Row>
            </Col> */}
            {/* <Col>
              <Row className="justify-content-center project">
              <Card style={{ width: "20rem" }}>
                  <Card.Img variant="top" src={L_PythonCLI} />
                  <Card.Body>
                    <Card.Title>Python Contact Book</Card.Title>
                    <Card.Text>
                    Description of project
                    </Card.Text>
                    <Button variant="primary">Demo</Button>
                    <Button variant="primary">Source Code</Button>
                  </Card.Body>
                </Card>
              </Row>
            </Col> */}
            {/* <Col>
              <Row className="justify-content-center project">
              <Card style={{ width: "20rem" }}>
                  <Card.Img variant="top" src={L_PythonCLI} />
                  <Card.Body>
                    <Card.Title>Python Contact Book</Card.Title>
                    <Card.Text>
                    Description of project
                    </Card.Text>
                    <Button variant="primary">Demo</Button>
                    <Button variant="primary">Source Code</Button>
                  </Card.Body>
                </Card>
              </Row>
            </Col> */}
          {/* </Row>
        </Container> */}
      </div>
    </div>
  );
};

export default Projects;
