import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";


const ParticlesPage = () => {
    const options = {
    
    particles: {
      number: {
        value: 40,
        density: {
          enable: true,
          area: 800
        }
      },
      color: {
        value: ["#65FFF0"]
      },
      shape: {
        type: "circle"
      },
      opacity: {
        value: 10
      },
      size: {
        value: { min: 1, max: 5 }
      },
      links: {
        enable: true,
        distance: 150,
        color: "#808080",
        opacity: 0.4,
        width: 1
      },
      move: {
        enable: true,
        speed: 2,
        direction: "none",
        random: false,
        straight: false,
        outModes: "out"
      }
    },
    interactivity: {
      events: {
        onHover: {
          enable: true,
          mode: "grab"
        },
        onClick: {
          enable: true,
          mode: "push"
        }
      },
      modes: {
        grab: {
          distance: 140,
          links: {
            opacity: 1
          }
        },
        push: {
          quantity: 4
        }
      }
    }
  };
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);



return (

<Particles options={options} init={particlesInit} />

)

}

export default ParticlesPage;